import { Mode as PartnershipMode } from '@callback-cats/partnerships-ts';
import { Badge } from '@cjdev-internal/visual-stack-x/Badge';

export function renderBadgeByStatus(mode: string) {
  switch (mode) {
    case PartnershipMode.LIVE:
      return (
        <Badge className="badge-override" variant="1">
          LIVE
        </Badge>
      );
    case PartnershipMode.TEST:
      return (
        <Badge className="badge-override" variant="2">
          TEST
        </Badge>
      );
    case PartnershipMode.OFF:
      return (
        <Badge className="badge-override" variant="9">
          OFF
        </Badge>
      );
  }
}
