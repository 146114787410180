const errorCodes = [400, 403, 404, 405, 500, 501, 502, 503, 504, 999] as const;
type ErrorCode = typeof errorCodes[number];

export class HttpError extends Error {
  public statusCode: ErrorCode;

  constructor(message: string, statusCode: number = 999) {
    super(message);
    this.statusCode = this.toErrorCode(statusCode);
  }

  private toErrorCode = (n: number): ErrorCode => {
    return errorCodes.includes(n as ErrorCode) ? (n as ErrorCode) : 999;
  };
}
