import React, { useState } from 'react';
import { useNavigate, useParams } from '@platform/cj-platform-navigation';
import {
  CJField,
  CJForm,
  CJFormBlock,
  useCJForm,
} from '@cjdev-internal/visual-stack-x/CJForm';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';
import intl from 'utils/intl';
import { defineMessages } from 'react-intl';
import { useToast } from '@cjdev-internal/visual-stack-x/Toast';
import { managePartnerships } from './managePartnerships';
import { PartnerFieldRow } from './PartnerFieldRow';
import {
  FormPartnership,
  keyField,
  partnerField,
  PartnershipPageProps,
  statusField,
} from './partnershipTypes';
import { Partnership, TrackedValue } from '@atossa/core';
import { Partner } from '@callback-cats/partnerships-ts';
import styled from 'styled-components';
import { LoadingAnimation } from '@cjdev-internal/visual-stack-x/LoadingAnimation';
const StyledLoadingAnimation = styled(LoadingAnimation)`
  margin: 20px auto;
  display: block;
  width: 100%;
  height: 50px;
`;

function generateDefaults(partnerships: TrackedValue<Partnership>[]) {
  const defaultValuesArray = partnerships?.map((partnership, index) => {
    const data: {
      [x: string]: string;
    } = {
      [partnerField + index.toString()]: partnership.value.name,
      [keyField + index.toString()]: partnership.value.key,
      [statusField + index.toString()]: partnership.value.mode,
    };
    return data;
  });
  return Object.assign({}, ...(defaultValuesArray || []));
}

function initializePartnerRows(partnerships: TrackedValue<Partnership>[]) {
  return [...Array(partnerships.length).keys()].map((index) => ({
    key: index.toString(),
    partnership: partnerships[index].value,
  }));
}

export const PartnershipPage = (props: PartnershipPageProps) => {
  const [toastMount, showToast] = useToast(true);
  const { api, user } = props;
  const cid = user.currentCompany.id.toString();
  const navigate = useNavigate();
  const { tagId: tagIdFromPath } = useParams<{ tagId: string }>();
  const tagIdFromParent = props.existingPartnerships.tagId;
  const partnerCount = Object.keys(Partner).length;

  const tagId = tagIdFromParent || tagIdFromPath || 'tagIdNotFound';

  const [partnerships, setPartnerships] = useState<TrackedValue<Partnership>[]>(
    props.existingPartnerships.partnerships || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAndSetPartnerships = async (cid: string, tagId: string) => {
    const newPartnershipsFromService = await props.api.fetchPartnership(cid, {
      id: tagId,
    });
    setPartnerships(newPartnershipsFromService);
    setPartnerRows(initializePartnerRows(newPartnershipsFromService));
    setPartnerRowCount(newPartnershipsFromService.length);
    return generateDefaults(newPartnershipsFromService);
  };

  const config = useCJForm({
    defaultValues: async () => {
      if (!tagIdFromParent && tagIdFromPath && partnerships.length === 0) {
        try {
          setIsLoading(true);
          return await fetchAndSetPartnerships(cid, tagId);
        } catch (e) {
          showToast({ message: (e as Error).message, type: 'warning' });
        } finally {
          setIsLoading(false);
        }
      }
      return generateDefaults(partnerships);
    },
  });

  const [partnerRows, setPartnerRows] = useState<
    { key: string; partnership?: FormPartnership }[]
  >(initializePartnerRows(partnerships));
  const [partnerRowCount, setPartnerRowCount] = useState<number>(
    partnerships ? partnerships.length : 0
  );

  const handleAddPartner = () => {
    setPartnerRowCount(partnerRowCount + 1);
    setPartnerRows([
      ...partnerRows,
      { key: partnerRowCount.toString(), partnership: undefined },
    ]);
  };
  const getCjFormBlock = () => {
    return (
      <>
        {partnerRowCount === 0 && tagIdFromPath && isLoading ? (
          <></>
        ) : (
          <>
            {partnerRowCount === 0 ? (
              <CJField columnSpan={'full'}>
                {intl.formatMessage(content.noPartners)}
              </CJField>
            ) : (
              <></>
            )}
            {partnerRows.map((row) => {
              return (
                <PartnerFieldRow
                  key={row.key}
                  id={row.key}
                  existingRows={partnerships.map(
                    (partnership) => partnership.value
                  )}
                  partnerRows={partnerRows}
                  setPartnerRows={setPartnerRows}
                  config={config}
                />
              );
            })}

            <CJField align="start">
              <Button
                type="link"
                onClick={handleAddPartner}
                disabled={partnerRowCount === partnerCount}
              >
                {'+ Add Partner'}
              </Button>
            </CJField>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <Row align="end">
              <Button
                htmlType="button"
                type="tertiary"
                onClick={() => {
                  navigate('/list');
                }}
              >
                CANCEL
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                disabled={!config.formState.isDirty}
              >
                SAVE
              </Button>
            </Row>
          </>
        )}
      </>
    );
  };
  return (
    <>
      {toastMount}
      <CJForm
        {...config}
        heading={`Tag ID - ${tagId}`}
        showRequiredIndicator={true}
        formPageLayoutMode
        columnCount={6}
        id="PartnershipForm"
        onSubmit={async (data) => {
          try {
            await managePartnerships(
              api,
              partnerships.map((partnership) => partnership.value),
              tagId,
              cid,
              data,
              partnerRowCount,
              navigate,
              showToast
            );
          } catch (e) {
            const failedMessage = intl.formatMessage(
              content.partnershipSaveFailAlert
            );
            showToast({ message: failedMessage, type: 'warning' });
          }
        }}
      >
        {isLoading ? (
          <StyledLoadingAnimation>
            <LoadingAnimation
              loadingMessage={intl.formatMessage(content.partnershipLoading)}
            />
          </StyledLoadingAnimation>
        ) : (
          <></>
        )}
        <CJFormBlock>{getCjFormBlock()}</CJFormBlock>
      </CJForm>
    </>
  );
};

export const content = defineMessages({
  noPartners: {
    id: 'atossa.partnershipPage.noPartners',
    defaultMessage: 'No Partner Added',
  },
  partnershipSaveFailAlert: {
    id: 'atossa.partnershipPage.saveFail',
    defaultMessage: 'Failed to save',
  },
  partnershipSaveSuccessAlert: {
    id: 'atossa.partnershipPage.saveSuccess',
    defaultMessage: 'Successfully saved',
  },
  partnershipLoading: {
    id: 'atossa.partnershipPage.loading',
    defaultMessage: 'Loading',
  },
});
