import {
  ActionMenuClickEvent,
  ActionMenuOptionDef,
  ColumnDefList,
  GetDetailRowDataParams,
  IDetailCellRendererParams,
} from '@cjdev-internal/visual-stack-x/DataGrid';
import { AdvertiserTagViewProps, content, DisplayUniversalTag } from './index';
import intl from 'utils/intl';
import { appConfig } from '../../appConfig';
import { readableUser } from '../../utils/trackedValue';
import { Partnership, TrackedValue } from '@atossa/core';
import { renderBadgeByStatus } from './BadgeByStatus';
import { useNavigate } from 'react-router-dom';

export const getColumnDefs = (): ColumnDefList<DisplayUniversalTag> => {
  return [
    {
      headerName: intl.formatMessage(content.tagTableHeaderId),
      field: 'value.id',
      flex: 10,
      cellRenderer: 'agGroupCellRenderer',
      showRowGroup: true,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderName),
      field: 'value.name',
      flex: 20,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderWebsiteUrl),
      field: 'value.url',
      flex: 21,
    },
    ...(appConfig.isConsentLoyaltyUiEnabled
      ? [
          {
            headerName: intl.formatMessage(content.tagTableHeaderConsentSignal),
            field: 'value.consentConfig.enableAdvertiserConsentSignal',
            flex: 12,
          },
        ]
      : []),
    ...(appConfig.isConsentLoyaltyUiEnabled
      ? [
          {
            headerName: intl.formatMessage(
              content.tagTableHeaderLoyaltyExemption
            ),
            field: 'value.consentConfig.enableAdvertiserLoyaltyOverride',
            flex: 12,
          },
        ]
      : []),
    {
      headerName: intl.formatMessage(content.tagTableHeaderLastUpdated),
      field: 'updatedAt',
      type: 'date',
      flex: 10,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderUpdatedBy),
      valueGetter: (p: { data: DisplayUniversalTag | undefined }) =>
        `${readableUser(p.data?.updatedBy)}`,
      flex: 15,
    },
  ];
};

export const getDetailsCellRenderParams = (props: AdvertiserTagViewProps) => {
  const detailCellRendererParams: Partial<
    IDetailCellRendererParams<TrackedValue<Partnership>>
  > = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      columnDefs: [
        {
          headerName: intl.formatMessage(content.partnerTableHeaderName),
          field: 'value.name',
        },
        {
          headerName: intl.formatMessage(content.partnerTableHeaderKey),
          field: 'value.key',
        },
        {
          headerName: intl.formatMessage(content.partnerTableHeaderMode),
          field: 'value.mode',
          cellRenderer: (mode: { value: string }) => {
            return renderBadgeByStatus(mode.value);
          },
        },
        {
          headerName: intl.formatMessage(content.tagTableHeaderLastUpdated),
          field: 'updatedAt',
          valueFormatter: (p: { data: TrackedValue<Partnership> }) => {
            const date = new Date(p.data.updatedAt);
            return (
              date.toLocaleDateString('en-US', { day: '2-digit' }) +
              '-' +
              date.toLocaleDateString('en-US', { month: 'short' }) +
              '-' +
              date.toLocaleDateString('en-US', { year: 'numeric' })
            );
          },
        },
        {
          headerName: intl.formatMessage(content.tagTableHeaderUpdatedBy),
          field: 'updatedBy',
          valueGetter: (p: { data: TrackedValue<Partnership> }) =>
            `${readableUser(p.data.updatedBy)}`,
        },
      ],
    },
    // get the rows for each Detail Grid
    getDetailRowData: (params: GetDetailRowDataParams) => {
      const tagIdSelected = params.data.value.id;
      const tagIdIndex = props.partnerships.findIndex(
        (elem) => elem.tagId === tagIdSelected
      );
      params.successCallback(props.partnerships[tagIdIndex].partnerships);
    },
  };
  return detailCellRendererParams;
};

export const getRowActions = (
  props: AdvertiserTagViewProps,
  navigate: ReturnType<typeof useNavigate>
) => {
  const rowActions: ActionMenuOptionDef<DisplayUniversalTag> = [
    {
      label: intl.formatMessage(content.editTagButton),
      onClick: (e) => {
        props.setTagUnderEdit(e.rowData.value);
      },
    },
    ...(appConfig.isPartnershipUiEnabled
      ? [
          {
            label: intl.formatMessage(content.manageTagPartnersButton),
            onClick: (e: ActionMenuClickEvent<DisplayUniversalTag>) =>
              handleManageTagPartners(props, navigate, e),
          },
        ]
      : []),
  ];
  return rowActions;
};

// Define the function to handle manage tag partners action
const handleManageTagPartners = (
  props: AdvertiserTagViewProps,
  navigate: ReturnType<typeof useNavigate>,
  e: { rowData: { value?: { id: string } } }
) => {
  const partnerRowData = props.partnerships.find(
    (elem) => elem.tagId === e.rowData.value?.id
  );
  navigate(`/${e.rowData.value?.id}/partnerships`, {
    state: partnerRowData,
  });
};
