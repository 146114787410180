import { appConfig } from '../appConfig';
import {
  Advertiser,
  AffApiCompany,
  CreateUniversalTag,
  isAdvertiser,
  isAffApiUser,
  isTrackedPartnership,
  isTrackedPartnershipArray,
  Partnership,
  TrackedValue,
  UniversalTag,
} from '@atossa/core';
import { sanitizeNameUrl } from '../utils/trackedValue';
import { getBearerToken, getUserId } from '@platform/platform-auth';
import { handleResponse } from './handleResponse';

import { FetchService } from './fetchWithErrorHandler';
export type Api = {
  fetchCompany: (cid: string) => Promise<AffApiCompany>;
  fetchAdvertiser: (cid: string) => Promise<Advertiser | null>;
  setCjDataEnabled: (
    cid: string,
    cjDataEnabled: boolean
  ) => Promise<Advertiser>;
  createTag: (cid: string, tag: CreateUniversalTag) => Promise<Advertiser>;
  editTag: (cid: string, tag: UniversalTag) => Promise<Advertiser>;
  fetchPartnerships: () => Promise<TrackedValue<Partnership>[]>;
  fetchPartnership: (
    cid: string,
    tag: UniversalTag
  ) => Promise<TrackedValue<Partnership>[]>;
  createPartnership: (
    cid: string,
    tag: UniversalTag,
    partnership: Partnership
  ) => Promise<TrackedValue<Partnership>>;
  editPartnership: (
    cid: string,
    tag: UniversalTag,
    partnership: Partnership
  ) => Promise<TrackedValue<Partnership>>;
};
class ApiService implements Api {
  constructor(private fetchService: FetchService) {}

  async fetchCompany(cid: string): Promise<AffApiCompany> {
    const token = await getBearerToken();
    const userId = await getUserId();

    const userResponse = await this.fetchService.fetchWithAuthAndErrorHandling(
      `https://members.cj.com/affapi/oauth/user/${userId}?includeActiveOnlyForBackwardsCompatibility=false`,
      token
    );

    const user = await handleResponse(userResponse, isAffApiUser);
    const company = user.companies.find(
      (company: { id: { toString: () => string } }) =>
        company.id.toString() === cid
    );
    if (!!company) {
      return company;
    }
    throw new Error(`User is not associated with company: ${cid}`);
  }

  async fetchAdvertiser(cid: string): Promise<Advertiser | null> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}`,
      token
    );

    if (response.status === 404) {
      return null;
    }
    return handleResponse(response, isAdvertiser);
  }

  async setCjDataEnabled(
    cid: string,
    cjDataEnabled: boolean
  ): Promise<Advertiser> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}`,
      token,
      {
        method: 'PATCH',
        body: JSON.stringify({ cjDataEnabled }),
      }
    );
    return handleResponse(response, isAdvertiser);
  }

  async createTag(cid: string, tag: CreateUniversalTag): Promise<Advertiser> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}/tags`,
      token,
      {
        method: 'POST',
        body: JSON.stringify(sanitizeNameUrl(tag)),
      }
    );
    return handleResponse(response, isAdvertiser);
  }

  async editTag(cid: string, tag: UniversalTag): Promise<Advertiser> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}/tags/${tag.id}`,
      token,
      {
        method: 'PATCH',
        body: JSON.stringify(
          sanitizeNameUrl({
            name: tag.name,
            url: tag.url,
            consentConfig: tag.consentConfig,
          })
        ),
      }
    );
    return handleResponse(response, isAdvertiser);
  }

  async fetchPartnerships(): Promise<TrackedValue<Partnership>[]> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}partnerships`,
      token
    );

    return handleResponse(response, isTrackedPartnershipArray);
  }

  async fetchPartnership(
    cid: string,
    tag: UniversalTag
  ): Promise<TrackedValue<Partnership>[]> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}/tags/${tag.id}/partnerships`,
      token
    );

    return handleResponse(response, isTrackedPartnershipArray);
  }

  async createPartnership(
    cid: string,
    tag: UniversalTag,
    partnership: Partnership
  ): Promise<TrackedValue<Partnership>> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}/tags/${tag.id}/partnerships`,
      token,
      {
        method: 'POST',
        body: JSON.stringify(partnership),
      }
    );
    return handleResponse(response, isTrackedPartnership);
  }

  async editPartnership(
    cid: string,
    tag: UniversalTag,
    partnership: Partnership
  ): Promise<TrackedValue<Partnership>> {
    const token = await getBearerToken();
    const response = await this.fetchService.fetchWithAuthAndErrorHandling(
      `${appConfig.apiUrl}advertisers/${cid}/tags/${tag.id}/partnerships`,
      token,
      {
        method: 'PATCH',
        body: JSON.stringify(partnership),
      }
    );
    return handleResponse(response, isTrackedPartnership);
  }
}

export const api: Api = new ApiService(new FetchService());
