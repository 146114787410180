import { HttpError } from './errorHelper';

export const handleResponse = async <T>(
  response: Response,
  isT: (obj: unknown) => obj is T
): Promise<T> => {
  if (response.status === 200) {
    const t = await response.json();
    if (isT(t)) {
      return t;
    } else {
      return Promise.reject(
        new HttpError(
          `Unexpected response: status (${
            response.status
          }) : body (${JSON.stringify(t)})`,
          response.status
        )
      );
    }
  } else {
    try {
      const text = await response.text();
      return Promise.reject(new HttpError(text, response.status));
    } catch (e) {
      return Promise.reject(
        new HttpError(`${response.status}`, response.status)
      );
    }
  }
};
