import { TrackedValue } from '../TrackedValue';
import {
  Mode as PartnershipMode,
  Partner,
} from '@callback-cats/partnerships-ts';

export interface Partnership {
  name: Partner;
  key: string;
  mode: PartnershipMode;
}

export const isPartnership = (object: unknown): object is Partnership => {
  const partnership = object as Partnership;
  return (
    !!partnership &&
    partnership.name !== undefined &&
    partnership.key !== undefined &&
    partnership.mode !== undefined
  );
};

export const isPartnershipArray = (
  object: unknown
): object is Partnership[] => {
  if (!Array.isArray(object)) {
    return false;
  }

  return object.every((item) => isPartnership(item));
};

export const isTrackedPartnership = (
  object: unknown
): object is TrackedValue<Partnership> => {
  const partnership = object as TrackedValue<Partnership>;
  return (
    !!partnership &&
    partnership.value.name !== undefined &&
    partnership.value.key !== undefined &&
    partnership.value.mode !== undefined
  );
};

export const isTrackedPartnershipArray = (
  object: unknown
): object is TrackedValue<Partnership>[] => {
  if (!Array.isArray(object)) {
    return false;
  }

  return object.every((item) => isTrackedPartnership(item));
};
