import { AffApiCompany, AffApiCompanySchema } from './Company';
import { z } from 'zod';

export type AffApiUser = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  isEmployee: boolean;
  companies: AffApiCompany[];
  employeeRoles?: string[];
};

export const AffApiUserSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  emailAddress: z.string().email(),
  companies: z.array(AffApiCompanySchema),
  isEmployee: z.boolean(),
  employeeRoles: z.array(z.string()).optional(),
});

export const isAffApiUser = (object: unknown): object is AffApiUser => {
  const user = object as AffApiUser;
  return AffApiUserSchema.strict().safeParse(user).success;
};
