import { Api } from '../../Api';
import { Partnership } from '@atossa/core';
import { FieldValues } from '@cjdev-internal/visual-stack-x/CJForm';
import { NavigateFunction } from 'react-router-dom';
import { ToastOptions } from '@cjdev-internal/visual-stack-x/Toast';
import * as R from 'ramda';
import intl from '../../utils/intl';
import { content } from './index';
import { keyField, partnerField, statusField } from './partnershipTypes';

export const managePartnerships = async (
  api: Api,
  existingPartnerships: Partnership[],
  tagId: string,
  cid: string,
  data: FieldValues,
  rowCount: number,
  navigate: NavigateFunction,
  showToast: (options: ToastOptions) => string
) => {
  const partnerships = [...Array(rowCount).keys()].map((row: number) => {
    const partnership: Partnership = {
      name: data[partnerField + row.toString()],
      key: data[keyField + row.toString()],
      mode: data[statusField + row.toString()],
    };
    return partnership;
  });
  let savedPartners: String[] = [];
  const leavePage = (async () => {
    let leavePage = true;
    await Promise.all(
      partnerships.map(async (partnership, index) => {
        try {
          if (index < existingPartnerships.length) {
            if (!R.equals(partnership, existingPartnerships[index])) {
              await api.editPartnership(cid, { id: tagId }, partnership);
              savedPartners.push(
                intl.formatMessage(content.partnershipSaveSuccessAlert) +
                  ' ' +
                  partnership.name +
                  '.'
              );
            }
          } else {
            await api.createPartnership(cid, { id: tagId }, partnership);
            savedPartners.push(
              intl.formatMessage(content.partnershipSaveSuccessAlert) +
                ' ' +
                partnership.name +
                '.'
            );
          }
        } catch (e) {
          const failedMessage =
            intl.formatMessage(content.partnershipSaveFailAlert) +
            ' ' +
            partnership.name +
            '. ' +
            (e as Error).message;
          showToast({ message: failedMessage, type: 'warning' });
          leavePage = false;
        }
      })
    );
    return leavePage;
  })();
  if (await leavePage) {
    navigate('/list', { state: savedPartners });
  } else {
    savedPartners.forEach((partnerMessage) => {
      showToast({ message: partnerMessage, type: 'success' });
    });
  }
};
