import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import {
  Advertiser,
  Partnership,
  TrackedValue,
  UniversalTag,
} from '@atossa/core';
import { CjDataView } from '../CjDataView';
import { toDisplayTrackedValue } from '../../utils/trackedValue';
import { DataGridEnterprise } from '@cjdev-internal/visual-stack-x/DataGridEnterprise';
import { ToastOptions } from '@cjdev-internal/visual-stack-x/Toast';

import { useNavigate } from 'react-router-dom';
import './override.css';
import { appConfig } from '../../appConfig';
import {
  getColumnDefs,
  getDetailsCellRenderParams,
  getRowActions,
} from './tagGridViewHelper';

export type DisplayUniversalTag = Partial<TrackedValue<UniversalTag>>;

export interface AdvertiserTagViewProps {
  advertiser: Advertiser;
  partnerships: { tagId: string; partnerships: TrackedValue<Partnership>[] }[];
  showToast: (options: ToastOptions) => void;
  cjDataViewOpen: () => void;
  setTagUnderEdit: React.Dispatch<
    React.SetStateAction<UniversalTag | undefined>
  >;
  setIsCreatingTag: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdvertiserTagView = (props: AdvertiserTagViewProps) => {
  const navigate = useNavigate();
  const { advertiser, partnerships } = props;
  const rowsPerPage = 10;

  const createButton = (
    <Button type="primary" onClick={() => props.setIsCreatingTag(true)}>
      {intl.formatMessage(content.createTagButton)}
    </Button>
  );

  const [containerToLoad, setContainerToLoad] = useState<HTMLElement | null>(
    null
  );
  // If the target element is ready and loaded
  // set the target element
  useEffect(() => {
    setContainerToLoad(document.getElementById('createTagButton'));
  }, []);

  const portal = containerToLoad && createPortal(createButton, containerToLoad);

  const masterDetail = true;

  const tagData: DisplayUniversalTag[] = advertiser.tags.map((tag) => ({
    updatedAt: undefined,
    updatedBy: undefined,
    ...tag,
    value: {
      ...(appConfig.isConsentLoyaltyUiEnabled
        ? {
            consentConfig: {
              enableAdvertiserConsentSignal:
                tag.value.consentConfig?.enableAdvertiserConsentSignal || false,
              enableAdvertiserLoyaltyOverride:
                tag.value.consentConfig?.enableAdvertiserLoyaltyOverride ||
                false,
            },
          }
        : {}),
      ...tag.value,
    },
  }));

  const data = tagData.map((tag) => ({
    ...tag,
    partnerships: partnerships,
  }));

  const columnDefs = getColumnDefs();
  const rowActions = getRowActions(props, navigate);
  const detailCellRendererParams = getDetailsCellRenderParams(props);

  const isRowMaster = useCallback(
    (data: Partial<TrackedValue<UniversalTag>>) => {
      const tagIdSelected = data.value?.id;
      const tagIdIndex = props.partnerships.findIndex(
        (elem) => elem.tagId === tagIdSelected
      );
      return data
        ? props.partnerships[tagIdIndex]?.partnerships.length > 0
        : false;
    },
    [props.partnerships]
  );

  return (
    <>
      {portal}
      <CjDataView
        cjDataEnabled={toDisplayTrackedValue(advertiser.cjDataEnabled)}
        openToggleCjDataModal={props.cjDataViewOpen}
      />
      <div>
        <DataGridEnterprise<DisplayUniversalTag>
          domLayout="autoHeight"
          title={intl.formatMessage(content.tagTableCaption)}
          columnDefs={columnDefs}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
          detailRowAutoHeight={true}
          isRowMaster={isRowMaster}
          pagination
          paginationPageSize={rowsPerPage}
          getRowId={(row) => row.value!.id}
          rowData={data}
          rowActions={rowActions}
          EXPLICIT_ENTERPRISE_AUTH_DO_NOT_USE_WITHOUT_PERMISSION
          licenseKey={
            'CompanyName=CJ,LicensedGroup=UI Developers,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-033492,SupportServicesEnd=28_February_2024_[v2]_MTcwOTA3ODQwMDAwMA==e71b57b00ce7853b180abad582d4631f'
          }
        />
      </div>
    </>
  );
};

export const content = defineMessages({
  tagTableCaption: {
    id: 'atossa.tagTable.caption',
    defaultMessage: 'Tags',
  },
  tagTableHeaderId: {
    id: 'atossa.tagTable.table.header.id',
    defaultMessage: 'ID',
  },
  tagTableHeaderName: {
    id: 'atossa.tagTable.table.header.name',
    defaultMessage: 'Name',
  },
  tagTableHeaderWebsiteUrl: {
    id: 'atossa.tagTable.table.header.websiteUrl',
    defaultMessage: 'Website URL',
  },
  tagTableHeaderConsentSignal: {
    id: 'atossa.tagTable.table.header.consentSignal',
    defaultMessage: 'Consent Signal',
  },
  tagTableHeaderLoyaltyExemption: {
    id: 'atossa.tagTable.table.header.loyaltyExemption',
    defaultMessage: 'Loyalty Exemption',
  },
  tagTableHeaderLastUpdated: {
    id: 'atossa.tagTable.table.header.lastUpdated',
    defaultMessage: 'Last Updated',
  },
  tagTableHeaderUpdatedBy: {
    id: 'atossa.tagTable.table.header.updatedBy',
    defaultMessage: 'Updated By',
  },
  tagTableHeaderActions: {
    id: 'atossa.tagTable.table.header.actions',
    defaultMessage: 'Actions',
  },
  tagTableDatatablePerPage: {
    id: 'atossa.tagTable.table.perPage',
    defaultMessage: '{0} per page',
  },
  tagTableDatatableTotalRecords: {
    id: 'atossa.tagTable.table.totalRecords',
    defaultMessage: '{0} total records',
  },
  createTagButton: {
    id: 'atossa.tagTable.createtag.button',
    defaultMessage: 'Create Tag',
  },
  editTagButton: {
    id: 'atossa.tagTable.edittag.button',
    defaultMessage: 'Edit Tag',
  },
  manageTagPartnersButton: {
    id: 'atossa.tagTable.managetag.partners.button',
    defaultMessage: 'Manage Tag Partners',
  },
  partnerTableHeaderName: {
    id: 'atossa.partnerTable.header.name',
    defaultMessage: 'Partner',
  },
  partnerTableHeaderKey: {
    id: 'atossa.partnerTable.header.key',
    defaultMessage: 'Key',
  },
  partnerTableHeaderMode: {
    id: 'atossa.partnerTable.header.mode',
    defaultMessage: 'Partner Status',
  },
});
