export class FetchService {
  /**
   * Makes an HTTP request with error handling
   *
   * @param url - The URL to send the request to
   * @param options - Request configuration including method, headers, body, etc.
   * @returns A Promise resolving to the fetch Response
   * @throws Error with a generic message if the network request fails
   * @remarks
   * - Uses the native fetch API internally
   * - Catches and logs network errors
   */
  async fetchWithErrorHandling(
    url: RequestInfo,
    options: RequestInit
  ): Promise<Response> {
    try {
      return await fetch(url, options);
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      throw new Error(
        'Error Message: An unexpected error occurred. Please try again.'
      );
    }
  }

  /**
   * Makes an authenticated HTTP request with error handling
   *
   * @param url - The URL to send the request to
   * @param token - The authentication token to include in the Authorization header
   * @param options - Optional request configuration (defaults: method='GET')
   * @returns A Promise resolving to the fetch Response
   * @throws Error with a generic message if the network request fails
   * @remarks
   * - Automatically sets 'Authorization: Bearer {token}' header
   * - Automatically sets 'Content-Type: application/json;charset=UTF-8' header
   * - Defaults to GET method if not specified
   */
  async fetchWithAuthAndErrorHandling(
    url: RequestInfo,
    token: string,
    options: RequestInit = {}
  ): Promise<Response> {
    if (!token) {
      console.error('Missing authentication token');
      throw new Error('Authentication error: No token provided');
    }

    // Set default method to GET if not specified
    const method = options.method || 'GET';

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json;charset=UTF-8',
      ...options.headers,
    };

    return this.fetchWithErrorHandling(url, {
      ...options,
      method,
      headers,
    });
  }
}
