import { Partnership } from '@atossa/core';
import {
  Partner,
  Mode as PartnershipMode,
} from '@callback-cats/partnerships-ts';
import React from 'react';
import {
  CJField,
  MultiSwitchInput,
  SelectInput,
  TextInput,
  useCJForm,
} from '@cjdev-internal/visual-stack-x/CJForm';
import intl from '../../utils/intl';
import { content as tagContent } from '../AdvertiserTagView';
import {
  FormPartnership,
  keyField,
  partnerField,
  statusField,
} from './partnershipTypes';

export const PartnerFieldRow = (props: {
  id: string;
  existingRows: Partnership[];
  partnerRows: { key: string; partnership?: FormPartnership }[];
  setPartnerRows: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        partnership?: FormPartnership;
      }[]
    >
  >;
  config: ReturnType<typeof useCJForm>;
}) => {
  if (props.config.getValues()[`${statusField}${props.id}`] === undefined) {
    props.config.setValue(`${statusField}${props.id}`, PartnershipMode.OFF);
  }
  return (
    <>
      <CJField
        columnSpan={1}
        required
        name={`${partnerField}${props.id}`}
        label={
          props.id === '0'
            ? intl.formatMessage(tagContent.partnerTableHeaderName)
            : undefined
        }
        help={
          props.config.watch(`${partnerField}${props.id}`) !==
          props.existingRows[parseInt(props.id)]?.name
            ? 'Changed'
            : ''
        }
      >
        <SelectInput
          required
          readOnly={parseInt(props.id) < props.existingRows.length}
          unpack
          name={`${partnerField}${props.id}`}
          placeholder="Partner"
          options={Object.values(Partner)
            .map((value) => ({ value, label: value }))
            .filter(
              (partner) =>
                !props.partnerRows
                  .map((row) => row.partnership?.name)
                  .includes(partner.value) ||
                props.partnerRows[parseInt(props.id)].partnership?.name ===
                  partner.value
            )}
          onChange={(e) => {
            const newPartnerRows = props.partnerRows.map((row) => {
              if (row.key === props.id) {
                return {
                  key: props.id,
                  partnership: {
                    name: e.target.value,
                    key: row.partnership?.key,
                    mode: row.partnership?.mode,
                  } as FormPartnership,
                };
              } else {
                return row;
              }
            });
            props.setPartnerRows([...newPartnerRows]);
          }}
        />
      </CJField>
      <CJField
        columnSpan={3}
        required
        name={`${keyField}${props.id}`}
        label={
          props.id === '0'
            ? intl.formatMessage(tagContent.partnerTableHeaderKey)
            : undefined
        }
        help={
          props.config.watch(`${keyField}${props.id}`) !==
          props.existingRows[parseInt(props.id)]?.key
            ? 'Changed'
            : ''
        }
      >
        <TextInput required name={`${keyField}${props.id}`} />
      </CJField>
      <CJField
        columnSpan={2}
        required
        name={`${statusField}${props.id}`}
        label={
          props.id === '0'
            ? intl.formatMessage(tagContent.partnerTableHeaderMode)
            : undefined
        }
        help={
          props.config.watch(`${statusField}${props.id}`) !==
          props.existingRows[parseInt(props.id)]?.mode
            ? 'Changed'
            : ''
        }
      >
        <MultiSwitchInput
          required
          name={`${statusField}${props.id}`}
          options={Object.values(PartnershipMode)
            .map((value) => {
              let color:
                | 'info'
                | 'warning'
                | 'success'
                | 'alert'
                | 'interactive' = 'info';
              switch (value) {
                case PartnershipMode.OFF:
                  color = 'warning';
                  break;
                case PartnershipMode.TEST:
                  color = 'alert';
                  break;
                default:
                  color = 'success';
              }

              return {
                id: `${value}`,
                content: value,
                color,
              };
            })
            .reverse()}
          optionWidth="70px"
        />
      </CJField>
    </>
  );
};
