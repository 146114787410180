import { Partnership, TrackedValue } from '@atossa/core';
import {
  Partner,
  Mode as PartnershipMode,
} from '@callback-cats/partnerships-ts';
import { Api } from '../../Api';
import { User } from '@platform/cj-platform-navigation';

export interface FormPartnership {
  name?: Partner;
  key?: string;
  mode?: PartnershipMode;
}

export const partnerField = 'partnerField';
export const keyField = 'keyField';
export const statusField = 'statusField';

export interface FormPartnership {
  name?: Partner;
  key?: string;
  mode?: PartnershipMode;
}

export interface PartnershipPageProps {
  api: Api;
  doc: Document;
  user: User;
  existingPartnerships: {
    tagId: string;
    partnerships?: TrackedValue<Partnership>[];
  };
}
