import { z } from 'zod';

export type AffApiCompany = {
  id: number;
  name: string;
  type: 'advertiser' | 'publisher';
  status: 'ACTIVE' | unknown;
  functionalCurrency: 'USD' | unknown;
  companyRole: unknown;
};

export const AffApiCompanySchema = z.object({
  id: z.number(),
  name: z.string().min(1),
  type: z.enum(['advertiser', 'publisher']),
});
